import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../firebase-config";

const BlogSection = () => {
  const [images, setImages] = useState([]);

  const getImages = () => {
    const q = query(collection(db, "galeria"));
    onSnapshot(q, (snapshot) => {
      let imagenes = [];
      snapshot.docs.forEach((doc) => {
        imagenes.push({
          ...doc.data(),
          id: doc.id,
          original: doc.data().imagen,
          thumbnail: doc.data().imagen,
        });
      });
      setImages(imagenes);
    });
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <section className="section bg-gallery" id="blog">
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Galería
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </Col>
          </Row>

          <ImageGallery items={images} />
        </div>
      </section>
    </>
  );
};

export default BlogSection;
