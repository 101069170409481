import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import ScrollspyNav from "./scrollSpy";

const Navbar = ({ isAuth, isAdmin }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const logout = async () => {
    auth.signOut();
    navigate("/");
  };

  return (
    <>
      <nav
        id="main_navbar"
        className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark"
      >
        <div className="container">
          <Link className="navbar-brand logo" to="/">
            <img src="images/bg-customized/logo2.png" alt="" height="30" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu" />
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ScrollspyNav
              scrollTargetIds={[
                "home",
                "about",
                "services",
                "clients",
                "interes",
                "blog",
                "news",
                "pqr",
                "contact",
                "news",
              ]}
              activeNavClass="active"
              scrollDuration="800"
              headerBackground="true"
              className="ml-auto"
            >
              <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li className="nav-item active">
                  <a href="#home" className="nav-link">
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#about" className="nav-link">
                    ¿Quiénes somos?
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#services" className="nav-link">
                    Servicios
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#clients" className="nav-link">
                    Aliados
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <span
                    className="nav-link f-12 dropdown-p"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Conoce más
                  </span>
                  <div
                    className="dropdown-menu bg-dark"
                    aria-labelledby="navbarDropdown"
                  >
                    <a href="#interes" className="nav-link text-white">
                      Datos de interés
                    </a>
                    <a href="#blog" className="nav-link text-white">
                      Galería
                    </a>
                    <a href="#news" className="nav-link text-white">
                      Noticias
                    </a>
                    <div className="dropdown-divider" />
                    {!isAuth ? (
                      <>
                        <Link
                          onClick={() => navigate("/login")}
                          to="/login"
                          className="nav-link text-white"
                        >
                          Iniciar Sesión
                        </Link>

                        <Link
                          to="/registro"
                          onClick={() => navigate("/registro")}
                          className="nav-link nav-item text-white"
                        >
                          Registrarse
                        </Link>
                      </>
                    ) : (
                      <>
                        {!!isAdmin && (
                          <Link
                            to="/galeria"
                            onClick={() => navigate("/galeria")}
                            className="nav-link nav-item text-white"
                          >
                            Administrar galería
                          </Link>
                        )}
                        {!!isAdmin && (
                          <Link
                            to="/noticias"
                            onClick={() => navigate("/noticias")}
                            className="nav-link nav-item text-white"
                          >
                            Administrar noticias
                          </Link>
                        )}
                        {!!isAdmin && (
                          <Link
                            to="/usuarios"
                            onClick={() => navigate("/usuarios")}
                            className="nav-link nav-item text-white"
                          >
                            Usuarios
                          </Link>
                        )}
                        <a
                          onClick={logout}
                          className="nav-link text-white"
                          href="/#"
                        >
                          Cerrar sesión
                        </a>
                      </>
                    )}
                  </div>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
