import React, { useState } from "react";
import { toast } from "react-toastify";
import { collection, doc, query, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const initialData = {
  nombres: "",
  apellidos: "",
  email: "",
  telefono: "",
};

const RegisterForm = () => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const handleChange = ({ target }) =>
    setData({ ...data, [target.name]: target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = data;
    try {
      delete data["password"];
      await createUserWithEmailAndPassword(auth, email, password);
      const q = query(collection(db, "usuarios"));
      setDoc(doc(q), data);
      setData(initialData);
      setLoading(false);
      toast.success("Has sido registrado exitosamente");
      navigate("/");
    } catch (error) {
      if (error.code === "auth/email-already-in-use")
        alert("Este correo ya está en uso");
    }
  };

  return (
    <div
      className="container shadow-lg  p-3 mb-5 bg-white rounded"
      style={{ width: "50%", marginTop: 100 }}
    >
      <>
        <div className="d-flex justify-content-center mb-3">
          <img
            width="100"
            height="auto"
            alt="Logo"
            src={"/images/custom/logo.png"}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-muted text-center">Registrarse</h2>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <input
                  name="nombres"
                  placeholder="Nombres"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  name="apellidos"
                  placeholder="Apellidos"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  name="telefono"
                  placeholder="Teléfono"
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  name="email"
                  placeholder="Correo"
                  type="email"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  name="password"
                  placeholder="Contraseña"
                  type="password"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-sm btn-success btn-block"
                >
                  {!!loading && (
                    <>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                      <span className="sr-only">Loading...</span>
                    </>
                  )}
                  Registrarse
                </button>
                <button
                  className="btn btn-dark btn-block"
                  onClick={() => navigate("/")}
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    </div>
  );
};

export default RegisterForm;
