import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import AboutSection from "../components/AboutSection";
import ServiceSection from "../components/ServiceSection";
import FeatureSection from "../components/FeatureSection";
import ClientSection from "../components/ClientSection";
import BlogSection from "../components/BlogSection";
// import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import FooterAlt from "../components/FooterAlt";
import { Carousel2 } from "../components/Carousel";
import NewsSection from "../components/NewsSection";

const Index = ({ isAuth, isAdmin, news }) => {
  useEffect(() => {
    document.getElementById("main_navbar").classList.add("navbar-light");
  }, []);

  return (
    <>
      {/* Navigation Menu */}
      <Navbar isAdmin={isAdmin} isAuth={isAuth} />

      {/* HomeSection Menu */}
      <Home />

      {/* AboutSection Menu */}
      <AboutSection />

      {/* ServiceSection Menu */}
      <ServiceSection />

      {/* Carousel */}
      <Carousel2 />

      {/* ClientSection Menu */}
      <ClientSection />

      {/* FeatureSection Menu */}
      <FeatureSection />

      {/* BlogSection Menu */}
      <BlogSection />

      {/* BlogSection Menu */}
      <NewsSection news={news} />

      {/* Footer Menu */}
      <Footer />

      {/* FooterAlt Menu */}
      <FooterAlt />
    </>
  );
};

export default Index;
