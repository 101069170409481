import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const NewsSection = ({ news }) => {
  const [fullWidth, setFullWidth] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSetFullWidth = (position) => {
    setIndex(position);
    setFullWidth(true);
  };

  return (
    <>
      <section className="section bg-news" id="news">
        <div className="container">
          <Row>
            <Col lg="4">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Noticias
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </Col>
          </Row>

          <Row style={{ maxHeight: 850, overflowY: "auto" }}>
            {news.map((noticia, index) => {
              return (
                <Col md="6" className="mb-2" key={index}>
                  <div
                    className="news"
                    onClick={() => handleSetFullWidth(index)}
                  >
                    <img
                      className="news-image"
                      src={noticia.imagen}
                      alt="noticia"
                    />

                    <div className="news-description">
                      <a onClick={() => handleSetFullWidth(index)}>
                        <h5>{noticia.titulo}</h5>
                      </a>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>

      <section className={!!fullWidth ? "news-full" : "news-full-hide"}>
        <i
          className="fas fa-times news-full-icon"
          onClick={() => setFullWidth(false)}
        />
        <Row>
          <Col lg="12" className="mb-3">
            <img
              width="150"
              alt=""
              height="auto"
              src={"/images/custom/logo.png"}
            />
          </Col>
        </Row>

        <Row className="news-full-row">
          {!!news && news.length > 0 && (
            <>
              <Col lg="6">
                <div className="news-full-img">
                  <img
                    width={600}
                    height={400}
                    alt=""
                    src={news[index].imagen}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="news-full-content">
                  <h4>{news[index].titulo}</h4>
                  <p>{news[index].descripcion}</p>
                </div>
              </Col>
            </>
          )}
        </Row>
      </section>
    </>
  );
};

export default NewsSection;
