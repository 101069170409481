import React from "react";
import { Row, Col } from "reactstrap";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";

const ClientSection = () => {
  return (
    <>
      <section className="section bg-light" id="interes">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-heading mb-1">
                <h3 className="mb-1 font-weight-light text-uppercase">
                  Datos de interés
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </div>
          </div>

          <Row>
            <Col span={12} style={{ marginTop: 20 }}>
              <div className="item">
                <Row>
                  <Col lg="12">
                    <div className="testi-content">
                      <div className="testi-box mt-4">
                        <p className="text-justify font-weight-light mb-0 f-18">
                          Nuestras rutas de recolección se realizan de forma
                          selectiva, fase en la cual captamos solo el material
                          reciclable. Estas rutas son frecuentemente
                          monitoreadas por nuestro personal para evaluar la
                          eficiencia del recorrido/recolección y estas a su vez
                          son operadas activamente a través de nuestros
                          recicladores y los habitantes de los barrios
                          beneficiados con nuestro servicio. Así mismo atendemos
                          a todos nuestros usuarios aliados en las áreas de
                          Clínicas, Hospitales, Empresas publicas y privadas,
                          Instituciones, Hoteles, Centros Comerciales y otros a
                          los cuales les prestamos nuestros servicios de una
                          manera exclusiva.
                        </p>
                        <br />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <img src={"images/custom/images/datos_interes.png"} alt="" />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ClientSection;
