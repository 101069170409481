import React from "react";
import { Row, Col } from "reactstrap";

const Home = () => {
  return (
    <>
      <section className="section home-6-bg" id="home">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="mt-40 home-5-content">
                    <h1 className="f-35 text-white font-weight-light home-6-title mb-0">
                      Coorrenacer
                    </h1>
                    <p className="text-white font-weight-light mt-4 f-16 mb-0">
                      Somos una cooperativa de recicladores de oficio que nos
                      dedicamos al manejo integral de los residuos sólidos
                      generando utilidades para mejorar la calidad de vida de
                      los asociados y su familia.
                    </p>
                  </div>
                </Col>

                <Col lg="6">
                  <div className="home-5-content home-6-content mt-40 p-4">
                    <img
                      width="400"
                      height="400"
                      src={"images/custom/logo.png"}
                      alt=""
                      className="img-fluid mx-auto d-block mover-img"
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="container-fluid">
              <Row className="row">
                <div className="home-1-shape">
                  <img
                    src={"images/home-6-shape.png"}
                    alt=""
                    className="img-fluid mx-auto w-100 d-block"
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
