import React from "react";

const ScrollspyNav = ({ children, className }) => {
  return (
    <div data-nav="list" className={className}>
      {children}
    </div>
  );
};

export default ScrollspyNav;
