import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyBsntROO9QzHOgOVqGjFdzg0PiVGrjK6j0",
  authDomain: "coorrenacer-b3afb.firebaseapp.com",
  projectId: "coorrenacer-b3afb",
  storageBucket: "coorrenacer-b3afb.appspot.com",
  messagingSenderId: "204033253234",
  appId: "1:204033253234:web:b40367859dbec8a2c4c7eb",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// export default {
//     apiKey: process.env.REACT_APP_APIKEY,
//     authDomain: process.env.REACT_APP_AUTHDOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID
// }
