import React from "react";
// import {Link} from 'react-router-dom';
import { Row, Col } from "reactstrap";

const MisionVisionValuesSection = () => {
  const AboutList = [
    {
      icon: "fas fa-balance-scale",
      title: "Honestidad",
    },
    {
      icon: "far fa-handshake",
      title: "Unión",
    },
    {
      icon: "fas fa-check",
      title: "Compromiso",
    },
    {
      icon: "fas fa-cog",
      title: "Responsabilidad",
    },
    {
      icon: "fas fa-fist-raised",
      title: "Fortaleza",
    },
    {
      icon: "fas fa-shield-alt",
      title: "Confianza",
    },
  ];

  const BlogList = [
    {
      title: "Misión",
      content:
        "COORRENACER  E.S.P.  tiene como objetivo principal  reducir la producción de residuos \n" +
        "aprovechables, y revalorizar la mayor cantidad posible de  materiales reciclables, comprometida al \n" +
        "mejoramiento ambiental de la cuidad, a través de la gestión de capacitación a la comunidad y \n" +
        "entidades.",
    },
    {
      title: "Visión",
      content:
        "COORRENACER  E.S.P.  será el ente gremial fortalecido y reconocido, autónomo, auto sostenible, \n" +
        "generadores de calidad para los recicladores, propósitos y facilitador de alternativas de \n" +
        "desarrollo. Con capacidad de interlocución en los ámbitos político, socio-económico, ante entidades \n" +
        "públicas y privadas de orden local, nacional e internacional con el fin de fortalecer y tecnificar \n" +
        "el gremio y así el mejorar la calidad de vida del reciclados y su familia.",
    },
  ];

  return (
    <>
      <Row className="mt-5">
        {BlogList.map((blog, index) => {
          return (
            <Col lg="12" key={index} className="mb-4">
              <div className="blog">
                <div className="blog-content text-left p-4">
                  <h4 className="font-weight-normal text-dark">{blog.title}</h4>
                  <p className="text-muted f-14">{blog.content}</p>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <Row>
        <Col lg="12">
          <h4 className="font-weight-normal text-dark text-center">
            Nuestros Valores
          </h4>
        </Col>
        {AboutList.map((about, index) => {
          return (
            <Col lg="4" sm="4" xs="12" key={index}>
              <div className="about-box about-light text-center p-3">
                <div className="about-icon mb-2">
                  <i className={about.icon} />
                </div>
                <h5 className="font-weight-light">{about.title}</h5>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default MisionVisionValuesSection;
