import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  const ServiceList = [
    {
      icon: "fas fa-money-bill-wave",
      title: "Comercialización de materiales reciclables",
      content: "Compramos y vendemos los residuos reciclables y chatarra.",
    },
    {
      icon: "fas fa-recycle",
      title: " Recolección y aprovechamiento de material reciclable",
      content:
        "Recogemos todo material reciclable de su empresa o negocio (cartón, vidrio, metal, papel, etc).",
    },
    {
      icon: "fas fa-truck",
      title: "Ruta de reciclaje",
      content:
        "Recogemos  el  material  reciclable  en  la  puerta  de  su  negocio,  empresa  o  institución  en horarios y frecuencias correspondientes a la ruta de su sector.",
    },
    {
      icon: "fas fa-tree",
      title: "Preservación de los recursos naturales",
      content:
        "A  través  de  la  celebración  de  contratos  o  convenios  con  empresas  publicas,  privadas, \n" +
        "organizaciones  e  instituciones,  desarrollamos  actividades  de  restauración  de  áreas \n" +
        "degradadas a través de la reforestación.",
    },
    {
      icon: "fas fa-broom",
      title: "Prestación de servicio de barrido y limpieza",
      content:
        "A  través  de  la  celebración  de  contratos  o  convenios  con empresas  públicas,  privadas, \n" +
        "organizaciones,  instituciones,  desarrollamos  actividades  de  limpieza,  barrido  de  vías  , \n" +
        "parques, zonas verdes, bodegas y su empresa en general.",
    },
    {
      icon: "fas fa-chalkboard-teacher",
      title:
        "Capacitación a la comunidad e instituciones educativas en separación de residuos a la fuente",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
  ];
  return (
    <>
      <section className="section" id="services">
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Servicios
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </Col>
          </Row>

          <Row>
            {ServiceList.map((service, index) => {
              return (
                <Col lg="4" md="6" key={index}>
                  <div className="service-box rounded mt-4 p-4">
                    <div className="service-icon mb-3">
                      <i className={service.icon} />
                    </div>
                    <div className="services-desc">
                      <div className="service-title mb-2 position-relative">
                        <h5 className="font-weight-normal mb-3">
                          <Link to="#" className="text-dark">
                            {service.title}
                          </Link>
                        </h5>
                      </div>
                      <p className="text-muted mb-3 f-14">{service.content}</p>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
