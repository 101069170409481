import React from "react";
import { Row, Col } from "reactstrap";
import MisionVisionValuesSection from "./MisionVisionValuesSection";

const AboutSection = () => {
  const AboutList = [
    {
      icon: "mdi mdi-lightbulb",
      title: "Honestidad",
    },
    {
      icon: "mdi mdi-projector-screen",
      title: "Unión",
    },
    {
      icon: "mdi mdi-nature",
      title: "Compromiso",
    },
    {
      icon: "mdi mdi-nature",
      title: "Responsabilidad",
    },
    {
      icon: "mdi mdi-nature",
      title: "Fortaleza",
    },
    {
      icon: "mdi mdi-nature",
      title: "Confianza",
    },
  ];
  return (
    <>
      <section className="section bg-about bg-light-about bg-light" id="about">
        <div className="container">
          <Row className="align-items-center">
            <Col md="6">
              <div className="title-heading">
                <h3 className="text-dark font-weight-light text-uppercase">
                  ¿Quiénes somos?
                </h3>
                <div className="title-border-simple position-relative mb-5" />
              </div>

              <div className="about-desc">
                <p className="text-muted f-16">
                  Somos una cooperativa prestadora de servicios públicos en la
                  modalidad de aprovechamiento, conformada por recicladores de
                  oficio que nos dedicamos al manejo integral de los residuos
                  solidos, reduciendo el impacto negativo que estos producen en
                  el medio ambiente y generando utilidades para el mejoramiento
                  de la calidad de vida de los asociados y su familia.
                </p>
              </div>
            </Col>

            <Col md="6">
              <div className="about-img light-img position-relative p-4">
                <img
                  src={"images/custom/photos/1.jpg"}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
          <MisionVisionValuesSection />
        </div>
      </section>
    </>
  );
};

export default AboutSection;
