import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase-config";
import { useNavigate } from "react-router-dom";

const UsersAdmin = ({ isAdmin }) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const getUsers = () => {
    const q = query(collection(db, "usuarios"));
    onSnapshot(q, (snapshot) => {
      let usuario = [];
      snapshot.docs.forEach((doc) => {
        usuario.push({ ...doc.data(), id: doc.id });
      });
      setUsers(usuario);
    });
  };

  useEffect(() => {
    if (!!isAdmin) {
      getUsers();
    } else navigate("/");
  }, []);

  return (
    <div className="container mt-5">
      {!!isAdmin ? (
        <>
          <div className="d-flex justify-content-center mb-3">
            <img
              width="100"
              height="auto"
              alt="Logo"
              src={"/images/custom/logo.png"}
            />
          </div>
          <h1>Gestión de usuarios</h1>
          <Row>
            <Col lg="12" className="mt-5">
              <table className="table table-hover">
                <thead className="thead-dark text-center">
                  <tr>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Teléfono</th>
                    <th>Correo</th>
                  </tr>
                </thead>

                <tbody className="text-center">
                  {!!users &&
                    users.length > 0 &&
                    users.map((user) => {
                      return (
                        <tr key={user.id}>
                          <td>{user.nombres}</td>
                          <td>{user.apellidos}</td>
                          <td>{user.telefono}</td>
                          <td>{user.email}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <button
                onClick={() => navigate("/")}
                className="btn btn-info btn-block btn-sm"
              >
                Volver
              </button>
            </Col>
          </Row>
        </>
      ) : (
        <div />
      )}
    </div>
  );
};

export default UsersAdmin;
