import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RegisterForm from "../components/RegisterForm";
import UsersAdmin from "../components/UsersAdmin";
import GalleryForm from "../components/GalleryForm";
import Index from "../pages/Index";
import Login from "../components/Login";
import NewsForm from "../components/NewsForm";
import { ToastContainer, toast } from "react-toastify";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../firebase-config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  doc,
  deleteDoc,
  query,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import uuid from "random-uuid-v4";

const initialData = {
  titulo: "",
  imagen: "",
  fecha: "",
  descripcion: "",
};

const MyRoute = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState(initialData);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const storage = getStorage();
  const auth = getAuth();

  const handleChange = ({ target }) => {
    if (!!target.files) setFile(target.files[0]);
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    delete data[""];
    if (!isEdit) {
      const storageRef = ref(storage, `images/${uuid()}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      try {
        await addDoc(collection(db, "noticias"), {
          ...data,
          imagen: downloadURL,
        });
      } catch (error) {
        console.log(error);
      }

      toast.success("Noticia añadida exitosamente");
      setData(initialData);
      setFile("");
      setLoading(false);
    } else {
      const storageRef = ref(storage, `images/${uuid()}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      try {
        const ImagenDoc = doc(db, "noticias", data.id);
        await updateDoc(ImagenDoc, {
          ...data,
          imagen: downloadURL,
        });
      } catch (error) {
        console.log(error);
      }

      toast.success("Noticia Actualizada exitosamente");
      setIsEdit(false);
      setLoading(false);
    }
  };

  const handleEdit = (id, dataEdit) => {
    setIsEdit(true);
    setData(dataEdit);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Seguro desea eliminar esta noticia?")) {
      await deleteDoc(doc(db, "noticias", id));
    } else return false;
  };

  const getNews = () => {
    const q = query(collection(db, "noticias"));
    onSnapshot(q, (snapshot) => {
      let noticia = [];
      snapshot.docs.forEach((doc) => {
        noticia.push({ ...doc.data(), id: doc.id });
      });
      setNews(noticia);
    });
  };

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid === auth.currentUser.uid) setIsAdmin(true);
        else setIsAdmin(false);
        setIsAuth(true);
      } else {
        setIsAdmin(false);
        setIsAuth(false);
      }
    });
  }, []);

  return (
    <Router basename={"/"}>
      <Routes>
        <Route
          exact
          path={`/`}
          element={<Index isAuth={isAuth} isAdmin={isAdmin} news={news} />}
        />
        <Route
          path={`/index-2`}
          element={<Index isAuth={isAuth} isAdmin={isAdmin} news={news} />}
        />
        <Route path={`/login`} element={<Login />} />
        <Route
          path={`/noticias`}
          element={
            <NewsForm
              isEdit={isEdit}
              isAdmin={isAdmin}
              loading={loading}
              news={news}
              data={data}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          }
        />
        <Route path={`/noticias`} element={<ToastContainer />} />
        <Route path={`/registro`} element={<RegisterForm />} />
        <Route path={`/registro`} element={<ToastContainer />} />
        <Route path={`/usuarios`} element={<UsersAdmin isAdmin={isAdmin} />} />
        <Route path={`/usuarios`} element={<ToastContainer />} />
        <Route path={`/galeria`} element={<GalleryForm isAdmin={isAdmin} />} />
        <Route path={`/galeria`} element={<ToastContainer />} />
      </Routes>
    </Router>
  );
};

export default MyRoute;
