import React from "react";
import { Row, Col } from "reactstrap";

const FeatureSection = () => {
  return (
    <>
      <section
        style={{ marginBottom: "-100px" }}
        className="section bg-features"
      >
        <div className="container">
          <Row className="align-items-center">
            <Col lg="6">
              <div className="features-img">
                <img
                  src={"images/custom/images/dangerous.png"}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="features-content">
                <div className="features-icon">
                  <i className="pe-7s-shuffle text-primary" />
                </div>
                <h4 className="font-weight-bold text-dark text-recycle">
                  Residuos no Reciclables
                </h4>
                <ul className="list-group list-recycle mt-4">
                  <li>
                    <p className="text-muted text-left f-15">
                      Empaque de pasabocas
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Papel y cartones sucios, húmedos o impregnados de grasa
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Colillas de cigarrillo
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Empaques plastificados, papel aluminio, fax e icopor
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Papel higiénico, servilletas
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section bg-features" style={{ marginTop: "-150px" }}>
        <div className="container">
          <Row className="align-items-center">
            <Col lg="6">
              <div className="features-content">
                <div className="features-icon">
                  <i className="pe-7s-science text-primary" />
                </div>
                <h4 className="font-weight-bold text-dark text-recycle">
                  Residuos Orgánicos
                </h4>
                <ul className="list-group list-recycle mt-4">
                  <li>
                    <p className="text-muted text-left f-15">
                      Residuos de comida, cáscara de frutas y verduras
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Residuos de jardín y madera
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6">
              <div className="features-img mt-20">
                <img
                  src={"images/custom/images/organic.png"}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section style={{ marginTop: "-90px" }} className="section bg-features">
        <div className="container">
          <Row className="align-items-center">
            <Col lg="6">
              <div className="features-img">
                <img
                  src={"images/custom/images/dangerous.png"}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="features-content">
                <div className="features-icon">
                  <i className="pe-7s-shuffle text-primary" />
                </div>
                <h4 className="font-weight-normal text-dark font-weight-bold text-recycle">
                  Residuos Peligrosos
                </h4>

                <ul className="list-group list-recycle mt-4">
                  <li>
                    <p className="text-muted text-left f-15">
                      Aserrín y estopas impregnados de aceites o productos
                      químicos
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Envases con pinturas, productos químicos, combustibles,
                      grasas, agujas, grasas de origen sanitario y plaguicidas
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">Pilas, baterías</p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Medicamentos vencidos
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="section bg-features" style={{ marginTop: "-90px" }}>
        <div className="container">
          <Row className="align-items-center">
            <Col lg="6">
              <div className="features-content">
                <div className="features-icon">
                  <i className="pe-7s-science text-primary" />
                </div>
                <h4 className="font-weight-bold text-dark text-recycle">
                  Residuos Reciclables
                </h4>
                <ul className="list-group list-recycle mt-4">
                  <li>
                    <p className="text-muted text-left f-15">
                      Papel de oficina, periódico y revistas
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Cartón, kraft y plegadiza
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Frascos y envases de vidrio
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Latas de gaseosas y metales
                    </p>
                  </li>
                  <li>
                    <p className="text-muted text-left f-15">
                      Plásticos como envases, bolsas y vasos
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6">
              <div className="features-img">
                <img
                  src={"images/custom/images/recyclable.png"}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default FeatureSection;
