import React, { Fragment, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const handleChange = ({ target }) =>
    setData({ ...data, [target.name]: target.value });

  const login = async (e) => {
    e.preventDefault();
    const { email, password } = data;
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      if (err.code === "auth/user-not-found")
        alert("No existe un usuario con esta cuenta");
      if (err.code === "auth/wrong-password")
        alert("Usuario o contraseña incorrectos");
      if (err.code === "auth/argument-error")
        alert(
          "La contraseña debe tener al menos 8 caracteres, mayúsculas y símbolos"
        );
    }
  };

  return (
    <div
      className="container shadow-lg  p-3 mb-5 bg-white rounded"
      style={{ width: "30%", marginTop: 150 }}
    >
      <Fragment>
        <div className="d-flex justify-content-center mb-3">
          <img
            width="100"
            height="auto"
            alt="Logo"
            src="/images/custom/logo.png"
          />
        </div>
        <form onSubmit={login}>
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-muted text-center">Iniciar sesión</h2>
              <div className="form-group">
                <input
                  name="email"
                  placeholder="Usuario"
                  type="email"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  name="password"
                  placeholder="Contraseña"
                  type="password"
                  className="form-control"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-sm  btn-success btn-block"
                >
                  Iniciar sesión
                </button>
                <button
                  className="btn btn-sm btn-dark btn-block"
                  onClick={() => navigate("/")}
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    </div>
  );
};

export default Login;
