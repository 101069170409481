import React, { useEffect, useState } from "react";
import "firebase/firestore";
import "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "reactstrap";

const NewsForm = ({
  isEdit,
  isAdmin,
  loading,
  news,
  data,
  handleEdit,
  handleDelete,
  handleChange,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const toggle = () => setModal(!modal);

  const handleOpenImage = (position) => {
    setIndex(position);
    setModal(true);
  };

  useEffect(() => {
    if (!isAdmin) navigate("/");
  });

  return (
    <>
      <div>
        <Modal centered={true} size="md" isOpen={modal} toggle={toggle}>
          <i onClick={toggle} className="fas fa-times modal-icon text-white" />
          {!!news && !!news.length && (
            <img src={news[index].imagen} alt="agendar_jornada" />
          )}
        </Modal>
      </div>

      {!!isAdmin ? (
        <div className="container">
          <Row>
            <Col lg="4" className="shadow p-3 mb-5 bg-white rounded mt-5">
              <div className="d-flex justify-content-center mb-3">
                <img
                  width="100"
                  height="auto"
                  alt="Logo"
                  src="/images/custom/logo.png"
                />
              </div>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="text-muted text-center mb-3">
                      {!isEdit ? "Añadir Noticia" : "Editar Noticia"}
                    </h2>
                    <div className="form-group">
                      <input
                        name="titulo"
                        placeholder="Título"
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        autoFocus
                        value={data.titulo}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        name="fecha"
                        placeholder="Fecha"
                        type="date"
                        className="form-control"
                        onChange={handleChange}
                        required
                        value={data.fecha}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="descripcion"
                        className="form-control"
                        rows={4}
                        onChange={handleChange}
                        required
                        value={data.descripcion}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-block"
                      >
                        {!!loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span className="sr-only">Loading...</span>
                            {!isEdit ? "Añadiendo Noticia" : "Editando Noticia"}
                          </>
                        ) : (
                          <div>
                            {!isEdit ? "Añadir noticia" : "Editar noticia"}
                          </div>
                        )}
                      </button>
                      <button
                        className="btn btn-sm btn-dark btn-block"
                        onClick={() => navigate("/")}
                      >
                        Volver
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>

            <Col
              lg="8"
              className="mt-5"
              style={{ height: 550, overflowY: "auto" }}
            >
              <table className="table table-hover table-responsive">
                <thead
                  className="thead-dark text-center"
                  style={{ position: "sticky" }}
                >
                  <tr>
                    <th>Título</th>
                    <th>Imágen</th>
                    <th>Fecha</th>
                    <th>Descripción</th>
                    <th>Acción</th>
                  </tr>
                </thead>

                <tbody className="text-center">
                  {!!news &&
                    news.length > 0 &&
                    news.map((noticia, index) => {
                      return (
                        <tr key={noticia.id}>
                          <td>{noticia.titulo}</td>
                          <td>
                            <i
                              className="fas fa-images image-icon"
                              onClick={() => handleOpenImage(index)}
                            />
                          </td>
                          <td>{noticia.fecha}</td>
                          <td>{noticia.descripcion}</td>
                          <td>
                            <i
                              className="fas fa-edit mr-3 mb-2 image-icon-edit"
                              onClick={() => {
                                handleEdit(noticia.id, noticia);
                                navigate("/noticias");
                              }}
                            />
                            <i
                              className="fas fa-trash-alt image-icon-delete mr-3"
                              onClick={() => handleDelete(noticia.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default NewsForm;
