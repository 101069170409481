import React, { useEffect, useState } from "react";
import "firebase/firestore";
import "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "reactstrap";
import { toast } from "react-toastify";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  collection,
  onSnapshot,
  query,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import uuid from "random-uuid-v4";

const GalleryForm = ({ isAdmin }) => {
  const navigate = useNavigate();
  const storage = getStorage();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(0);

  const toggle = () => setModal(!modal);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isEdit) {
      const storageRef = ref(storage, `galeria/${uuid()}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      try {
        await addDoc(collection(db, "galeria"), {
          imagen: downloadURL,
        });
      } catch (error) {
        console.log(error);
      }
      toast.success("Imagen añadida exitosamente");
      setFile({});
      setLoading(false);
    } else {
      const storageRef = ref(storage, `galeria/${uuid()}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      try {
        const ImagenDoc = doc(db, "galeria", id);
        await updateDoc(ImagenDoc, {
          imagen: downloadURL,
        });
      } catch (error) {
        console.log(error);
      }
      setIsEdit(false);
      setLoading(false);
    }
  };

  const handleChange = ({ target }) => setFile(target.files[0]);

  const handleEdit = (id) => {
    setId(id);
    setIsEdit(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Seguro desea eliminar esta imagen?")) {
      const q = query(collection(db, "galeria"));
      deleteDoc(doc(q, id));
    } else return false;
  };

  const handleOpenImage = (position) => {
    setIndex(position);
    setModal(true);
  };

  const getImages = () => {
    const q = query(collection(db, "galeria"));
    onSnapshot(q, (snapshot) => {
      let imagenes = [];
      snapshot.docs.forEach((doc) => {
        imagenes.push({ ...doc.data(), id: doc.id });
      });
      setImages(imagenes);
    });
  };

  useEffect(() => {
    if (!!isAdmin) getImages();
    else navigate("/");
  }, []);

  return (
    <>
      <div>
        <Modal centered={true} size="md" isOpen={modal} toggle={toggle}>
          <i onClick={toggle} className="fas fa-times modal-icon text-white" />
          {!!images && !!images.length && (
            <img src={images[index].imagen} alt="agendar_jornada" />
          )}
        </Modal>
      </div>

      {!!isAdmin ? (
        <div className="container">
          <Row>
            <Col lg="4" className="shadow p-3 mb-5 bg-white rounded mt-5">
              <div className="d-flex justify-content-center mb-3">
                <img
                  width="100"
                  height="auto"
                  alt="Logo"
                  src="/images/custom/logo.png"
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="text-muted text-center mb-3">
                      {!isEdit ? "Añadir Imagen" : "Editar Imagen"}
                    </h2>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary btn-block"
                      >
                        {!!loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span className="sr-only">Loading...</span>
                            {!isEdit ? "Añadiendo Imagen" : "Editando Imagen"}
                          </>
                        ) : (
                          <div>
                            {!isEdit ? "Añadir Imagen" : "Editar Imagen"}
                          </div>
                        )}
                      </button>
                      <button
                        className="btn btn-sm btn-dark btn-block"
                        onClick={(e) => {
                          navigate("/");
                          e.stopPropagation();
                        }}
                      >
                        Volver
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>

            <Col
              lg="8"
              className="mt-5"
              style={{ height: 350, overflowY: "auto" }}
            >
              <table className="table table-hover">
                <thead className="thead-dark text-center">
                  <tr>
                    <th>Imagen</th>
                    <th>Acción</th>
                  </tr>
                </thead>

                <tbody className="text-center">
                  {!!images &&
                    images.length > 0 &&
                    images.map((imagen, index) => {
                      return (
                        <tr key={imagen.id}>
                          <td>
                            <i
                              className="fas fa-images image-icon"
                              onClick={() => handleOpenImage(index)}
                            />
                          </td>
                          <td>
                            <i
                              className="fas fa-edit mr-3 image-icon-edit"
                              onClick={() => {
                                handleEdit(imagen.id, imagen);
                              }}
                            />
                            <i
                              className="fas fa-trash-alt image-icon-delete"
                              onClick={() => handleDelete(imagen.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};

export default GalleryForm;
